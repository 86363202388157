import React from "react"

import { Header } from "components/molecules"
import { Footer } from "components/organisms"
import { Block, Button, Text } from "components/atoms"
import { navigate } from "gatsby"
import { withTheme } from "hocs"
import SEO from "../components/seo"

const NotFoundPage = ({ pageContext }) => (
  <Block position={"relative"}>
    <SEO title="404: Not found" />
    <Header crmOption={pageContext.crmOption} />
    <Block
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      mt={[100]}
      onClick={() => navigate("/")}
    >
      <Text>
        <h2>PAGE NOT FOUND</h2>
      </Text>
      <Button.Contain>GO TO HOME PAGE</Button.Contain>
    </Block>
    <Block mt={'10%'}>
      <Footer />
    </Block>
  </Block>
)

export default withTheme()(NotFoundPage)
